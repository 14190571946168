<template>
	<div class="p_20 indexBanner">
		<el-dialog :visible.sync="isShow" :close-on-click-modal="false" @close="closeDialog">
			<!-- <el-divider></el-divider> -->
			<el-form :model="form" label-width="80px">
				<el-form-item label="产品名">
					<el-input v-model="form.name" class="w_400"></el-input>
				</el-form-item>
				
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closeDialog">取 消</el-button>
				<el-button type="primary" @click="submit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isShow: false,
				
				form: {
					id:'',
					name:'',
				},
			}
		},
		methods: {
			//提交
			submit(){
				let params = this.form;
				// let params = new FormData();
				
				// params.append('id', this.form.id);
				// params.append('title', this.form.title);
				// params.append('sort', this.form.sort);
				// params.append('text1', this.form.text1);
				// params.append('text2', this.form.text2);
				// params.append('text3', this.form.text3);
				// params.append('url', this.form.url);
				// params.append('language', 0);
				
				
				// if(this.upImg.file) {
				// 	params.append('file', this.upImg.file);
				// }
				
				if(this.form.id) {
					this.edit(params);
				} else {
					this.add(params)
				}
			},
			
			
			//新增
			add(params){
				this.axiosPost('Product/add', params , res => {
					if(res.data.code === 200) {
						this.msgSuccess(res.data.message);
						this.$parent.getTableData();
						this.isShow = false;
					}else {
						this.msgError(res.data.message)
					}
				})
			},
			
			//编辑
			edit(params) {
				this.axiosPost('Product/editor', params , res => {
					if(res.data.code === 200) {
						this.msgSuccess(res.data.message);
						this.$parent.getTableData();
						this.isShow = false;
					}else {
						this.msgError(res.data.message)
					}
				})
			},
			
			//关闭窗口 清空数据
			closeDialog(){
				this.form = {
					id:'',
					name:''
				}
				
				this.isShow = false;
			}
		}
	}
</script>

<style scoped>

</style>
