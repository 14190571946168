export default {
	// 获取页面数据
	getTableData() {
		this.onLoad(true)
		var params = {
			// account_type_id:this.id,
			page: this.$refs.Page.pagination.current,
			limit: this.$refs.Page.pagination.pageSize
		}

		if(this.search) {
			params.name = this.search
		}

		this.axiosGets("Product/list", params, function(res) {

			if(res.data.code === 200) {
		this.onLoad(false)

			this.tableData = res.data.data.data;
			//分页
			this.total = res.data.data.total
			// console.log(this.total);
			}
		}) 
	},

	//添加
	add() {
		// this.history('/admin/edit')
		this.$refs.Edit.isShow = true;
	},

	//编辑
	edit(scope) {
		
		this.$refs.Edit.form.id = scope.id;
		this.$refs.Edit.form.name = scope.name;
		// this.$refs.Edit.form.account_type_id = scope.account_type.id;
		// this.$refs.Edit.getClient(scope.account_type.id)
		// this.$refs.Edit.form.client_id = scope.client.id;


		this.$refs.Edit.isShow = true;
	},

	//删除
	remove(scope) {
		this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		}).then(() => {

			this.sureRemove(scope.id);
		}).catch(() => {
			this.$message({
				type: 'info',
				message: '已取消删除'
			});
		});
	},

	//确定删除
	sureRemove(id) {

		this.axiosGets("Product/delete?id=" + id, {}, function(res) {
			if (res.data.code === 200) {
				this.getTableData();
				this.msgSuccess(res.data.message)
			} else {
				this.msgError(res.data.message)
			}
		})
	}
}
